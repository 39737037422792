/* eslint-disable @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */

import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N20 } from '@atlaskit/theme/colors';

import {
	EMOJI_SIZE_LARGE,
	EMOJI_SIZE_MEDIUM,
} from '@confluence/emoji-title/entry-points/constants';

import cursorImg from './img/customCursor.svg';

type PresenterModePortalContainerProps = {
	isCustomCursorActive: boolean;
};

type TitleContainerProps = {
	isCustomSitesPageTitleFFOn?: boolean;
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	hasEmoji?: boolean;
	width?: number;
};

type HeaderImageContainerProps = {
	isCustomSitesPageTitleFFOn?: boolean;
};

type EmojiWrapperProps = {
	isCustomSitesPageTitleFFOn?: boolean;
	hasCoverPicture?: boolean;
};

type TitleTextWrapperProps = {
	shouldAddLeftPaddingCustomSites?: boolean;
	shouldAddLeftPadding?: boolean;
};

const screenSizeXS = 1080;
const screenSizeS = 1280;
const screenSizeM = 1536;
const screenSizeL = 2560;
const screenSizeHeaderImageBreakpoint = 1792;
const CALCULATE_SCALED_COVER_IMAGE_HEIGHT_PRESENTER_MODE =
	'min(max(calc(100vw * (7 / 38)), 140px), 750px)';
export const emojiVerticalAlignmentOffsetScreenSizeXS = -122;
export const emojiVerticalAlignmentOffsetScreenSizeSM = -104;
export const emojiVerticalAlignmentOffsetScreenSizeL = -95;
export const emojiVerticalAlignmentOffsetScreenSizeXL = -82;
const zoomLevelXSToM = 1.3;
const zoomLevelL = 2;

const cursorImage = `url(${cursorImg}), auto`;

const headerImageMediaQuery = {
	[`@media only screen and (min-width: ${screenSizeHeaderImageBreakpoint}px)`]: {
		height: '360px',
	},
	[`@media only screen and (min-width: ${screenSizeM}px) and (max-width: ${screenSizeHeaderImageBreakpoint}px)`]:
		{
			height: '240px',
		},
	[`@media only screen and (max-width: ${screenSizeM}px)`]: {
		height: '240px',
	},
};

export const mToLScreenZoomLevel = '1.5';

export const PresenterModePortalContainer = styled.div<PresenterModePortalContainerProps>({
	width: '100vw',
	height: '100vh',
	backgroundColor: token('elevation.surface', N0),
	position: 'fixed',
	top: 0,
	left: 0,
	overflowY: 'scroll',
	overflowX: 'hidden',
	display: 'flex',
	justifyContent: 'center',
	cursor: ({ isCustomCursorActive }) => (isCustomCursorActive ? cursorImage : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-tableHeader-sorting-icon': {
		display: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-tableHeader-sortable-column__button': {
		cursor: ({ isCustomCursorActive }) =>
			isCustomCursorActive ? `${cursorImage} !important` : 'auto !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'h1#title-text': {
		textAlign: 'center',
		paddingBottom: token('space.400', '32px'),
		justifyContent: 'center',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'span.inline-highlight': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		cursor: 'auto !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		background: 'none !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		borderBottom: 'none !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
		boxShadow: 'none !important',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'span.heading-anchor-wrapper': {
		display: 'none',
	},
});

export const HeaderImageContainer = styled.div<HeaderImageContainerProps>({
	height: ({ isCustomSitesPageTitleFFOn }) =>
		isCustomSitesPageTitleFFOn
			? `${CALCULATE_SCALED_COVER_IMAGE_HEIGHT_PRESENTER_MODE} !important`
			: 'unset',
	width: '100vw',
	marginBottom: token('space.1000', '80px'),
	background: token('color.background.neutral', N20),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	...headerImageMediaQuery,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.renderer-page-cover-picture': {
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		"[class*='UnsplashWrapper']": {
			width: '100%',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		"[class*='LoadingDiv']": {
			maxWidth: 'unset',
			width: '100%',
			marginTop: token('space.0', '0px'),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			...headerImageMediaQuery,
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		img: {
			margin: token('space.0', '0px'),
			maxWidth: 'unset',
			borderRadius: 'unset',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
			...headerImageMediaQuery,
		},
	},
});

export const NoHeaderImageSpacer = styled.div({
	marginBottom: token('space.400', '32px'),
	height: '150px',
});

export const scaleTo = (scale, isFirefox) => {
	return isFirefox ? `transform: scale(${scale})` : `zoom: ${scale};`;
};

export const PresenterModeZoomContainer = styled.div<{ isFirefox: boolean }>({
	margin: '0 auto',
	transformOrigin: 'top',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media only screen and (min-width: ${screenSizeL}px)`]: {
		width: '1520px',
		transform: ({ isFirefox }) => (isFirefox ? `scale(${zoomLevelL})` : undefined),
		zoom: ({ isFirefox }) => (isFirefox ? undefined : zoomLevelL.toString()),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media only screen and (min-width: ${screenSizeM}px) and (max-width: ${screenSizeL}px)`]: {
		width: '1140px',
		transform: ({ isFirefox }) => (isFirefox ? `scale(${mToLScreenZoomLevel})` : undefined),
		zoom: ({ isFirefox }) => (isFirefox ? undefined : mToLScreenZoomLevel.toString()),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media only screen and (min-width: ${screenSizeXS}px) and (max-width: ${screenSizeM}px)`]: {
		transform: ({ isFirefox }) => (isFirefox ? `scale(${zoomLevelXSToM})` : undefined),
		zoom: ({ isFirefox }) => (isFirefox ? undefined : zoomLevelXSToM.toString()),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media only screen and (min-width: ${screenSizeS}px) and (max-width: ${screenSizeM}px)`]: {
		width: '988px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media only screen and (min-width: ${screenSizeXS}px) and (max-width: ${screenSizeS}px)`]: {
		width: '760px',
	},
});

export const LoadingContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100vh',
});

export const SpinnerContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flex: 1,
});

export const TitleContainer = styled.div<TitleContainerProps>({
	display: 'flex',
	justifyContent: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'initial'),
	flexDirection: ({ isCustomSitesPageTitleFFOn, isTitleCenterAligned, hasCoverPicture }) =>
		isCustomSitesPageTitleFFOn && (isTitleCenterAligned || hasCoverPicture) ? 'column' : 'initial',
	alignItems: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'baseline'),
	gap: ({ isCustomSitesPageTitleFFOn, hasCoverPicture, isTitleCenterAligned, hasEmoji }) =>
		isCustomSitesPageTitleFFOn && (hasCoverPicture || (isTitleCenterAligned && hasEmoji))
			? token('space.200', '16px')
			: 'initial',
	boxSizing: 'border-box',
	minHeight: '76px',
	width: '100%',
	maxWidth: '760px',
	margin: `0 auto ${token('space.400', '32px')} auto`,
	marginTop: ({ isCustomSitesPageTitleFFOn, hasCoverPicture, hasEmoji, width }) =>
		isCustomSitesPageTitleFFOn && hasCoverPicture && hasEmoji
			? `${getEmojiVerticalAlignmentOffset(width)}px`
			: 'initial',
	wordBreak: 'break-word',
	wordWrap: 'break-word',
});

export const Title = styled.h1({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&#title-text': {
		font: token('font.heading.xlarge'),
		fontWeight: token('font.weight.medium'),
		marginTop: token('space.0', '0px'),
	},
	fontWeight: token('font.weight.medium'),
	margin: token('space.0', '0px'),
	color: token('color.text', '#333'),
	wordBreak: 'break-word',
	wordWrap: 'break-word',
	minHeight: token('space.500', '40px'),
	paddingTop: token('space.075', '6px'),
	display: 'flex',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: `calc(64px / ${zoomLevelXSToM})`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media (min-width: ${screenSizeM}px)`]: {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: `calc(72px / ${mToLScreenZoomLevel})`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
	[`@media (min-width: ${screenSizeL}px)`]: {
		// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
		fontSize: `calc(84px / ${zoomLevelL})`,
	},
});

const leftPaddingNonCustomSites = (shouldAddLeftPadding) =>
	shouldAddLeftPadding ? token('space.100', '8px') : 'unset';

export const TitleTextWrapper = styled.div<TitleTextWrapperProps>({
	paddingLeft: ({ shouldAddLeftPaddingCustomSites, shouldAddLeftPadding }) =>
		shouldAddLeftPaddingCustomSites
			? token('space.150', '12px')
			: leftPaddingNonCustomSites(shouldAddLeftPadding),
});

const emojiCoverPictureMaxWidth = (hasCoverPicture) =>
	hasCoverPicture ? `${EMOJI_SIZE_LARGE}px` : `${EMOJI_SIZE_MEDIUM}px`;

export const EmojiWrapper = styled.div<EmojiWrapperProps>({
	display: 'flex',
	justifyContent: 'center',
	maxWidth: ({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
		isCustomSitesPageTitleFFOn ? emojiCoverPictureMaxWidth(hasCoverPicture) : '26px',
	alignItems: 'center',
});

export const FlagsContainer = styled.div({
	zoom: '0.7',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"div[role='alert']": {
		margin: `${token('space.250', '20px')} auto`,
		width: '600px',
	},
});

export const ButtonContainer = styled.span({
	marginLeft: token('space.100', '8px'),
	display: 'inline-block',
});

export const getEmojiVerticalAlignmentOffset = (width): number => {
	if (!width || width < screenSizeXS) {
		return emojiVerticalAlignmentOffsetScreenSizeXS;
	} else if (width < screenSizeM) {
		return emojiVerticalAlignmentOffsetScreenSizeSM;
	} else if (width < screenSizeL) {
		return emojiVerticalAlignmentOffsetScreenSizeL;
	} else {
		return emojiVerticalAlignmentOffsetScreenSizeXL;
	}
};
