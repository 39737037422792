import React from 'react';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { FlagsStateContainer } from '@confluence/flags';

import { usePresenterModeContext } from './PresenterModeContext';
import { PresenterModePortal } from './PresenterModePortal';

/**
 * A simple wrapper for determining whether the PresenterModePortal
 * should be shown based on whether or not presenter mode has been
 * activated.
 */
export const PresenterModeWrapper = ({ contentId, spaceKey }) => {
	const { isPresenterModeActive, isBrowserFullscreen } = usePresenterModeContext();

	if (isPresenterModeActive || isBrowserFullscreen) {
		return (
			<Subscribe to={[FlagsStateContainer]}>
				{(flags: FlagsStateContainer) => (
					<PresenterModePortal
						contentId={contentId}
						spaceKey={spaceKey}
						setFlagsVisibility={flags.setFlagsVisibility}
					/>
				)}
			</Subscribe>
		);
	}

	return null;
};
