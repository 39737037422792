import type { FC } from 'react';
import React from 'react';

import Flag from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

type HeaderPresentationModeProps = {
	title: string;
};

export const ErrorFlag: FC<HeaderPresentationModeProps> = ({ title }) => {
	return (
		<Flag
			appearance="error"
			icon={
				<ErrorIcon label="Error" secondaryColor={token('color.background.danger.bold', R400)} />
			}
			id="error"
			key="error"
			title={title}
		/>
	);
};
