export enum TitleAlignmentType {
	LEFT = 'LEFT',
	CENTER = 'CENTER',
}

export type TitleContentPropertiesType = {
	titleLayoutAlignment?: TitleAlignmentType;
	titlePropertySubtitle?: string;
	titlePropertySubtitleEnabled?: boolean;
	titlePropertyBylineEnabled?: boolean;
	titlePropertyEmojiEnabled?: boolean;
	headerImageStylingEnabled?: boolean;
};

export const defaultPropertyValue = {
	titleLayoutAlignment: TitleAlignmentType.LEFT,
	titlePropertySubtitle: '',
	titlePropertySubtitleEnabled: false,
	titlePropertyBylineEnabled: false,
	titlePropertyEmojiEnabled: true,
	headerImageStylingEnabled: false,
};

export const enum COVERPICTUREWIDTH {
	FULL = 'full',
	FIXED = 'fixed',
}
