import { useLayoutEffect } from 'react';

/**
 * While in use, this hook effectively locks scrolling for the document.
 * It's intended to be used while the PresenterModePortal is shown to
 * prevent a "double scrolling" effect.
 */
export const useLockBodyScroll = () => {
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = originalStyle;
		};
	}, []);
};
