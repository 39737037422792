import type { TitleAlignmentType } from './contentTopperConstants';
import { defaultPropertyValue } from './contentTopperConstants';

export type PropertyValue = {
	titleLayoutAlignment?: TitleAlignmentType;
	titlePropertySubtitle?: string;
	titlePropertySubtitleEnabled?: boolean;
	titlePropertyBylineEnabled?: boolean;
	titlePropertyEmojiEnabled?: boolean;
	headerImageStylingEnabled?: boolean;
};

export const contentPropertyHelper = (jsonProperty): PropertyValue => {
	let value;

	try {
		value = JSON.parse(jsonProperty.value);

		// objects returned from the content property can be double stringified

		const quoteMatches = value.match(/"/g);
		const quoteCount = quoteMatches ? quoteMatches.length : 0;

		// if quoteCount != null this means that the object is double wrapped as a string and needs to be parsed again
		if (quoteCount) {
			try {
				value = JSON.parse(value);
			} catch (error) {
				value = defaultPropertyValue;
				throw new Error(`unable to parse value: ${error.message}`);
			}
		}
	} catch (error) {
		value = defaultPropertyValue;
		throw error;
	}

	return value;
};
