import gql from 'graphql-tag';

export const PresenterModeContentTitleQuery = gql`
	query PresenterModeContentTitleQuery($contentId: ID!) {
		content(id: $contentId) {
			nodes {
				id
				title
				emojiTitlePublished: properties(key: "emoji-title-published") {
					nodes {
						id
						key
						value
					}
				}
			}
		}
	}
`;
