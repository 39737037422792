import { useBooleanFeatureFlag } from '@confluence/session-data';

import { COVERPICTUREWIDTH } from './contentTopperConstants';

export const useIsFixedWidthImageOption = ({
	coverPictureWidth,
}: {
	coverPictureWidth?: COVERPICTUREWIDTH;
}) => {
	const isForceFixedWidthOn = useBooleanFeatureFlag(
		'confluence.frontend.custom-sites.fixed-width-header-image-option',
	);

	return isForceFixedWidthOn || coverPictureWidth === COVERPICTUREWIDTH.FIXED;
};
