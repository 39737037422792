import { ContentAppearanceType } from '@confluence/content-appearance';
import {
	COVERPICTUREWIDTH,
	TitleAlignmentType,
	getTitleContentProperty,
} from '@confluence/custom-sites-extensions';

import { DEFAULT_POSITION_VALUE } from './pageCoverPictureConstants';
import { calcDynamicCoverImageHeight } from './calcDynamicCoverImageHeight';
import { CoverPictureIdPropertyKey } from './pageCoverPictureTypes';

export function propertyToId(p) {
	let value;
	let version;
	try {
		value = JSON.parse(p.value);
		version = parseInt(JSON.parse(p.version.number));

		// Counting quotes to determine if the value we receive is a string (old cover photos experience without repositioning feature)
		// or object (new cover photos experience with repositioning feature - has a position property in the object).
		// The new designs will have value as an object but stored as a string: "{"id":"http://website.com","position":45}"
		// We need to JSON.parse it again to turn it into an object. The old cover photos will just have value as a string: value: "123-abc",
		// so there is no need to JSON parse it again. We will set its position as the default position value to support this new value format.

		const quoteMatches = value.match(/"/g);
		const quoteCount = quoteMatches ? quoteMatches.length : 0;

		// if quoteCount != null this mean value is part of the new experience so we need to JSON parse the info again since it is a stored as a string and we want the object
		if (quoteCount) {
			try {
				value = JSON.parse(value);
			} catch (e) {
				value = null;
			}
		} else {
			// otherwise it was just a regular string (part of old experience without repositioning)
			value = { id: value, position: DEFAULT_POSITION_VALUE };
		}
	} catch (e) {
		value = null;
		version = null;
	}
	return p
		? {
				value,
				version,
			}
		: null;
}

export function parseContentAppearance(appearanceValue) {
	let appearance;

	if (appearanceValue) {
		try {
			appearance = JSON.parse(appearanceValue);
		} catch (e) {
			appearance = null;
		}
	}

	return appearance ?? ContentAppearanceType.DEFAULT;
}

// function used to get props RendererPageCoverPicture needs
export function getRendererCoverPictureInfo(contentQueryData) {
	const pageWidthType =
		contentQueryData?.content?.nodes?.[0]?.appearancePublished?.nodes?.[0]?.value || `"default"`;
	const properties = contentQueryData?.content?.nodes?.[0]?.properties?.nodes || [];
	const published = properties.find((p) => p?.key === CoverPictureIdPropertyKey.PUBLISHED);
	const coverPictureWidth = (contentQueryData?.content?.nodes?.[0]?.metadata?.frontend
		?.coverPictureWidth || COVERPICTUREWIDTH.FULL) as COVERPICTUREWIDTH;
	const { titleContentProperties } = getTitleContentProperty(contentQueryData);
	const isTitleCenterAligned =
		titleContentProperties?.titleLayoutAlignment == TitleAlignmentType.CENTER;
	const coverPicture = {
		draft: null,
		published: propertyToId(published),
	};

	let publishedValue: string;
	try {
		publishedValue = JSON.parse(pageWidthType);
	} catch (e) {
		publishedValue = 'default';
	}

	const contentAppearance = {
		draft: '',
		published: publishedValue,
	};

	const hasCoverPicture = !!coverPicture?.published?.value?.id;

	return {
		coverPicture,
		contentAppearance,
		hasCoverPicture,
		coverPictureWidth,
		isTitleCenterAligned,
	};
}

export const getDynamicCoverImageHeight = (width): number => {
	return calcDynamicCoverImageHeight(width);
};
