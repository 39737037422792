import React from 'react';
import { styled } from '@compiled/react';

import Spinner from '@atlaskit/spinner/spinner';

import { LoadableLazy } from '@confluence/loadable';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	width: '100%',
});

const LoadingSpinner = () => (
	<SpinnerContainer>
		<Spinner size="large" />
	</SpinnerContainer>
);

export const LazyQRCodeGenerator = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-QRCodeComponent" */ './QRCodeComponent'))
			.QRCodeComponent,
	loading: LoadingSpinner,
});
