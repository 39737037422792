import React, { useContext, useEffect, useMemo, useState } from 'react';

type ControlPanelContextType = {
	isSettingsOpen: boolean;
	setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isNavigationMenuOpen: boolean;
	setIsNavigationMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	width: string;
};
const ControlPanelContext = React.createContext<ControlPanelContextType>({
	isSettingsOpen: false,
	setIsSettingsOpen: () => {
		// Intentional no-op
	},
	isNavigationMenuOpen: false,
	setIsNavigationMenuOpen: () => {
		// Intentional no-op
	},
	width: '1',
});

type ControlPanelContextProviderProps = {
	children: React.ReactNode;
};

export const ControlPanelContextProvider = ({ children }: ControlPanelContextProviderProps) => {
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false);
	const [width, setWidth] = useState('1');

	const headerWidth =
		document.getElementById('presenter-mode-control-panel')?.getBoundingClientRect().width || 242;

	useEffect(() => {
		setWidth(headerWidth.toString());
	}, [headerWidth]);

	const data = useMemo(
		() => ({
			isSettingsOpen,
			setIsSettingsOpen,
			isNavigationMenuOpen,
			setIsNavigationMenuOpen,
			width,
		}),
		[isSettingsOpen, setIsSettingsOpen, isNavigationMenuOpen, setIsNavigationMenuOpen, width],
	);

	return <ControlPanelContext.Provider value={data}>{children}</ControlPanelContext.Provider>;
};

export const useControlPanelContext = () => {
	return useContext(ControlPanelContext);
};
