import type { FC } from 'react';
import React, { useEffect, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N40 } from '@atlaskit/theme/colors';
import AkButton from '@atlaskit/button';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { useSpaceId } from '@confluence/space-utils';

import { ControlPanelContextProvider } from './ControlPanelContext';
import { ButtonContainer } from './presenterModeStyles';
import { LazyNavigation, LazySettingsMenu } from './LazyButtons';
import { usePresenterModeContext } from './PresenterModeContext';

export const HeaderControlPanel = 'presenter-mode-control-panel';

const headerContainerStyles = css({
	alignItems: 'center',
	backgroundColor: token('elevation.surface.overlay', N0),
	position: 'fixed',
	top: token('space.300', '24px'),
	right: token('space.300', '24px'),
	display: 'flex',
	zIndex: '800',
	padding: token('space.150', '12px'),
	boxShadow: token(
		'elevation.shadow.overlay',
		'0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
	),
	borderRadius: '4px',
});

const verticalDividerStyles = css({
	height: '24px',
	width: '0px',
	borderLeft: `1px solid ${token('color.border', N40)}`,
});

const rightControlPanelStyles = css({
	marginLeft: 'auto',
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
});

type HeaderPresentationModeProps = {
	onSwitchCursorClick: (newValue: boolean) => void;
	contentRendered: boolean;
	spaceKey: string;
	contentId: string;
};

const i18n = defineMessages({
	exit: {
		id: 'presentation-mode.header.exit',
		description: "Message shown on 'Exit' button.",
		defaultMessage: 'Exit',
	},
});

export const HeaderPresenterMode: FC<HeaderPresentationModeProps> = ({
	onSwitchCursorClick,
	contentRendered,
	spaceKey,
	contentId,
}) => {
	const { exitPresenterMode, isBrowserFullscreen, setIsBrowserFullscreen } =
		usePresenterModeContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const spaceId = useSpaceId(spaceKey);

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'viewPresentScreen',
				attributes: {
					objectId: contentId,
					containerId: spaceId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, contentId, spaceId]);

	const exit = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'present',
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'close',
				objectId: contentId,
				containerId: spaceId,
			},
		}).fire();
		exitPresenterMode();
	}, [createAnalyticsEvent, exitPresenterMode, contentId, spaceId]);

	return (
		<TransparentErrorBoundary attribution={Attribution.COMMENTS}>
			<ControlPanelContextProvider>
				<div data-testid="presentation-mode-header">
					<div css={headerContainerStyles} id={HeaderControlPanel}>
						<div css={rightControlPanelStyles}>
							<LazyNavigation
								contentRendered={contentRendered}
								contentId={contentId}
								spaceKey={spaceKey}
							/>
							<div css={verticalDividerStyles} />
							<LazySettingsMenu
								onSwitchCursorClick={onSwitchCursorClick}
								contentId={contentId}
								spaceKey={spaceKey}
							/>
							<ButtonContainer id="presenter-mode-exit-button">
								<AkButton
									testId="presentation-mode-button-close"
									onClick={isBrowserFullscreen ? () => setIsBrowserFullscreen(false) : exit}
								>
									{intl.formatMessage(i18n.exit)}
								</AkButton>
							</ButtonContainer>
						</div>
					</div>
				</div>
			</ControlPanelContextProvider>
		</TransparentErrorBoundary>
	);
};
