import { useEffect } from 'react';

/**
 * This hook is responsible for invoking the function from FlagsStateContainer to hide
 * flags while in Presenter Mode. Flag visiblity is returned to normal when the hook
 * is cleaned up.
 */
export const useHideFlags = (setFlagsVisibility: (areFlagsVisible: boolean) => Promise<void>) => {
	useEffect(() => {
		void setFlagsVisibility(false);

		return () => {
			void setFlagsVisibility(true);
		};
	}, [setFlagsVisibility]);
};
