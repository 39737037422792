import gql from 'graphql-tag';

const pageTitleInnerFragment = gql`
	fragment pageTitleInnerFragment on PaginatedJsonContentPropertyList {
		nodes {
			id
			key
			value
			version {
				number
			}
		}
	}
`;

export const TitleContentPropertyQuery = gql`
	query TitleContentPropertyQuery($contentId: ID, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				id
				pageTitlePublished: properties(key: "page-title-property-published") {
					...pageTitleInnerFragment
				}
				pageTitleDraft: properties(key: "page-title-property-draft") {
					...pageTitleInnerFragment
				}
				metadata {
					frontend {
						coverPictureWidth
					}
				}
			}
		}
	}

	${pageTitleInnerFragment}
`;
export const TitleContentPropertyEditorQuery = gql`
	query TitleContentPropertyEditorQuery($contentId: ID, $status: [String], $draftShareId: String) {
		content(id: $contentId, status: $status, draftShareId: $draftShareId) {
			nodes {
				id
				pageTitleDraft: properties(key: "page-title-property-draft") {
					...pageTitleInnerFragment
				}
				metadata {
					frontend {
						coverPictureWidth
					}
				}
			}
		}
	}
	${pageTitleInnerFragment}
`;

export const HomepageIdQuery = gql`
	query HomepageIdQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			homepage {
				id
			}
		}
	}
`;
