import { useEffect } from 'react';
import fscreen from 'fscreen';

import { usePresenterModeContext } from './PresenterModeContext';

/**
 * When run, this hook enters fullscreen mode using the top-level
 * `document.documentElement`. It is meant to be used within the
 * PresenterModePortal to enter fullscreen mode, and then exit
 * using the return function when the component is unmounted.
 */
export const useFullscreenMode = () => {
	const { exitPresenterMode } = usePresenterModeContext();
	useEffect(() => {
		// Event handler for when entering/exiting fullscreen mode
		const handleFullscreenChange = () => {
			/**
			 * This handles the case where the user has pressed Esc instead of using
			 * the "Exit" button to close presenter mode. In this case, the browser
			 * will be forced out of fullscreen mode, but presenter mode will still
			 * technically be active, so we need to listen for the event to make
			 * sure we exit presenter mode.
			 */
			if (!fscreen.fullscreenElement) {
				exitPresenterMode();
			}
		};

		fscreen.addEventListener('fullscreenchange', handleFullscreenChange, false);
		return () => {
			/**
			 * This handles the common case where the user clicks the "Exit" button to close
			 * presenter mode, which will cause this callback to run.
			 */
			if (fscreen.fullscreenElement) {
				fscreen.exitFullscreen();
			}
			fscreen.removeEventListener('fullscreenchange', handleFullscreenChange, false);
		};
	}, [exitPresenterMode]);
};
