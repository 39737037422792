import gql from 'graphql-tag';

export const PresenterModeCoverPictureQuery = gql`
	query PresenterModeCoverPictureQuery($contentId: ID, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				id
				properties(keys: ["cover-picture-id-published"]) {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
			}
		}
	}
`;
